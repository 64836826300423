import "./menu.css"

import { slide as Slide } from 'react-burger-menu'
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import LanguagePicker from "./language-picker"

interface MenuItem {
  id: string
  label: string
  path: string
  children?: MenuItem[]
}

const MenuItem = ({ data }: { data: MenuItem }) => {
  const className = ['menu-item']
  const hasChildren = data.children && data.children.length > 0
  if (hasChildren) { className.push('menu-item-has-children') }

  const { i18n } = useTranslation()
  const prefix = `/${i18n.language.split("_")[0]}/`
  const path = data.path?.replace(prefix, "/")

  return <div className={className.join(' ')}>
    <Link to={path}>
      {data.label}
      {hasChildren && <FontAwesomeIcon icon={faAngleDown} /> }
    </Link>
    {hasChildren && <div className="sub-menu">
      {data.children?.map((m: MenuItem, i) => <MenuItem key={i} data={m} />)}
    </div>}
  </div>
}

const Menu = () => {
  const { i18n } = useTranslation()
  const { menus } = useStaticQuery(graphql`
    query MenuQuery {
      menus: allWpMenu(filter: { locations: {in: PRIMARY} }) {
        nodes {
          id
          name
          locations
          language
          menuItems {
            nodes {
              id
              label
              path
              parentId
            }
          }
        }
      }
    }
  `)

  const language = i18n.language.split("_")[0]
  let menu = menus.nodes.find((m: { language: string}) => m.language === language)
  if (!menu) {
    if (language === "fr") {
      menu = {
        menuItems: {
          nodes: [
            {
              id: "home",
              label: "Acceuil",
              path: "/",
            },
            {
              id: "exchanges",
              label: "Bourses Crypto",
              path: "/exchanges/",
            }
          ]
        }
      }
    } else {
      return <></>
    }
  }
  const topLevelMenu = []
  const menuItems = menu.menuItems.nodes
  for (const item of menuItems) {
    menuItems[item.id] = item
    item.path = item.path?.replace("site_category", "sites")
    item.children = []
  }

  for (const item of menuItems) {
    if (item.parentId) {
      const parent = menuItems[item.parentId]
      parent.children.push(item)
    } else {
      topLevelMenu.push(item)
    }
  }

  return (
    <Slide className="menu" right>
      {topLevelMenu.map((m, i) => <MenuItem key={i} data={m} />)}
      <LanguagePicker />
    </Slide>
  )
}

export default Menu
