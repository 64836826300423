import "./media-object.css"
import React from "react"

interface MediaObjectProps {
  className?: string
  media: React.ReactNode
  children: React.ReactNode
}

const MediaObject = (props: MediaObjectProps) => {
  const { media, children } = props
  return <div className={`media-object ${props.className}`}>
    {media && <div className="column-media">
      {media}
    </div>}
    <div className="column-content">
      {children}
    </div>
  </div>
}

export default MediaObject