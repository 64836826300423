import "./menu-footer.css"

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

interface MenuItem {
  id: string
  parentId?: string
  label: string
  path: string
  children?: MenuItem[]
}

const MenuItem = ({ data }: { data: MenuItem }) => {
  const className = ['menu-item']
  const hasChildren = data.children && data.children.length > 0
  if (hasChildren) { className.push('menu-item-has-children') }

  const { i18n } = useTranslation()
  const prefix = `/${i18n.language.split("_")[0]}/`
  const path = data.path?.replace(prefix, "/")

  return <div className={className.join(' ')}>
    {path && <Link to={path}>{data.label}</Link>}
    {!path && <div>{data.label}</div>}
    {hasChildren && <div className="sub-menu">
      {data.children?.map((m: MenuItem, i) => <MenuItem key={i} data={m} />)}
    </div>}
  </div>
}

const Menu = () => {
  const { i18n } = useTranslation()
  const { menus } = useStaticQuery(graphql`
    query FooterMenuQuery {
      menus: allWpMenu {
        nodes {
          id
          name
          locations
          language
          menuItems {
            nodes {
              id
              label
              path
              parentId
            }
          }
        }
      }
    }
  `)

  const language = i18n.language.split("_")[0]
  let menu = menus.nodes.find((m: { language: string}) => m.language === language)
  let topLevelMenu = []
  const menuItems: Record<string, MenuItem> = {}

  if (menu) {
    for (const item of menu.menuItems.nodes) {
      menuItems[item.id] = item
      item.path = item.path?.replace("site_category", "sites")
      item.children = []
    }

    for (const item of menu.menuItems.nodes as MenuItem[]) {
      if (item.parentId) {
        const parent = menuItems[item.parentId]
        parent.children?.push(item)
      } else {
        topLevelMenu.push(item)
      }
    }
  }

  if (language === "fr") {
    topLevelMenu = [
      {
        id: "legal",
        label: "Mentions légales",
        children: [
          {
            id: "risks",
            label: "Divulgation des risques",
            path: "/divulgation-des-risques",
          },
          {
            id: "accuracy",
            label: "Divulgation sur l’exactitude des informations",
            path: "/accuracy-disclosure",
          },
          {
            id: "affiliations",
            label: "Divulgation des affiliations",
            path: "/affiliate-disclosure",
          },
        ]
      }
    ] as MenuItem[]
  }

  if (topLevelMenu.length === 0) { return <></> }

  return (
    <div className="menu-footer">
      {topLevelMenu.map((m, i) => <MenuItem key={i} data={m} />)}
    </div>
  )
}

export default Menu
