/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface Prefix {
  lang: string
  href: string
  url?: string
}
interface Props {
  description: string
  lang: string
  prefixes?: (string | Prefix)[]
  meta: Array<{
    property: string
    content: string
  }>
  title: string
  url?: string
  schema?: string
}

const fixSlashes = (url: string) => {
  const placeholder = "___PLACEHOLDER___"
  const protocol = "https://"
  return (url.endsWith("/") ? url : `${url}/`)
    .replace(protocol, placeholder)
    .replace(/\/\//g, "/")
    .replace(placeholder, protocol)
}

const Seo = (props: Props) => {
  const { description, lang, meta, title, schema, url } = props
  const { wp, wpUser } = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
            description
          }
        }

        # if there's more than one user this would need to be filtered to the main user
        wpUser {
          twitter: name
        }
      }
    `
  )

  const metaDescription = description || wp.generalSettings?.description
  const schemaClean = (schema || "")
    .replace(/<script.*?>/, "")
    .replace(/<\/script.*?>/, "")
    .replace(/data\.madcrypto\.com/g, "madcrypto.com")

  const prefixes: Prefix[] = [
    {
      lang: 'en',
      href: "https://www.madcrypto.com"
    },
    {
      lang: 'fr',
      href: "https://fr.madcrypto.com"
    },
    {
      lang: 'x-default',
      href: "https://www.madcrypto.com"
    },
  ].filter((prefix) => {
    return !props.prefixes || props.prefixes.includes(prefix.lang)
  })
  for (const p of props.prefixes || []) {
    if (typeof p === "string") {
      continue
    }
    prefixes.push(p)
  }

  const canonicalPrefix = lang.indexOf("fr") > -1 ? "https://fr.madcrypto.com" : "https://www.madcrypto.com"
  const hasCanonical = prefixes.length > 0

  return (
    <Helmet
      htmlAttributes={{
        lang: lang.replace("_", "-"),
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: wpUser?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    > 
      {typeof(url) === 'string' && prefixes.map((prefix, i) => 
        <link key={i} rel="alternate" hreflang={prefix.lang} href={fixSlashes(prefix.url ? prefix.url : `${prefix.href}/${url}`)} />
      )}
      {hasCanonical && typeof(url) === 'string' && <link rel="canonical" href={fixSlashes(`${canonicalPrefix}/${url}`)} />}
      <script type="application/ld+json">
        {schemaClean}
      </script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default Seo
