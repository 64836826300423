import "./language-picker.css"

import React, { useState } from "react"
import { useTranslation } from "react-i18next"

const LanguagePicker = () => {
  const { t, i18n } = useTranslation()
  const language = i18n.language.split("_")[0]
  return <select className="language-picker" value={language} onChange={(event) => {
    const lang = event.target.value
    if (lang !== language) {
      const domain = document.location.host.replace(/.*?\./, "")
      const subdomain = (lang === "fr") ? "fr" : "www"
      const href = `https://${subdomain}.${domain}`
      window.location.href = href
    }
  }}>
    <option value="en">🇺🇸 {t("English")}</option>
    <option value="fr">🇫🇷 {t("Français")}</option>
  </select>
}

export default LanguagePicker