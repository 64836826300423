import "@/css/style.css"
import "@/css/layout.css"
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'

import React, { useEffect } from "react"
import { Link } from "gatsby"
// @ts-ignore
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { navigate, HLocation } from "@reach/router"


import Menu from "@/components/menu"
import MenuFooter from "@/components/menu-footer"
import Logo from "@/components/logo"

import LightBulb from "@/images/lightbulb.inline.svg"
import MediaObject from "@/components/media-object"
import Seo from "@/components/seo"
import useScript from "@/hooks/useScript"
import { useTranslation } from "react-i18next"

const cookieFirstID = "madcrypto.com-38202244-1189-4f3d-8307-d91de5e72992"

interface Props {
  children: React.ReactNode
  pageContext: any
  title: string
  className?: string
  location: HLocation
  seo?: {
    title?: string
    description?: string
    schema?: string
    url?: string
    prefixes?: string[]
  }
}

const Layout = ({ children, pageContext, title, seo, className, location }: Props) => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    if (!location.pathname.endsWith('/')) {
      navigate(`${location.pathname}/`);
    }
  }, []);

  const {
    breadcrumb: { crumbs },
  } = pageContext
  crumbs.forEach((item: any) => item.crumbLabel = t(item.crumbLabel));
  const classNames = ["page"]
  if (className) { classNames.push(className) }

  useScript(`https://consent.cookiefirst.com/sites/${cookieFirstID}/consent.js`)

  const seoTitle = seo?.title || title || "We are Mad about Crypto, MadCrypto.com"
  const seoDescription = seo?.description || "Your guide to the mad world of crypto: Exchange reviews, staking reviews, and everything you need to know before investing in cryptocurrencies."

  return <div id="page" className={classNames.join(" ")}>
    <Seo title={seoTitle} prefixes={seo?.prefixes} lang={i18n.language} description={seoDescription} schema={seo?.schema} url={seo?.url} />
    <Header />
    <main id="main">
      {crumbs?.length > 1 && <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        crumbLabel={t(title)}
      />}
      {children}
    </main>
    <Footer />
  </div>
}

export default Layout

const Header = () => <header id="header">
  <div className="header-inner">
    <div className="header-logo">
      <Link to="/" id="logo">
        <Logo />
      </Link>
    </div>
  
    <div className="header-box-menu">
      <Menu />
    </div>
  </div>
</header>

const footerInner = <div className="footer-inner">
  <MediaObject className="container footer-cta" media={<LightBulb />}>
    <h3>Can't decide which crypto exchange to choose?</h3>
    <p>Our 1 min quiz will help you find the best crypto exchange for YOU.</p>
    <Link to="#" className="button">
      Find out
    </Link>
  </MediaObject>
</div>


const Footer = () => {
  const { t } = useTranslation()
  const copyright = `Copyright © ${new Date().getFullYear()} MadCrypto. | All Rights Reserved`
  return <footer id="footer">

    <div className="footer-inner">
      <div className="company-info">
        <Logo />
        <p dangerouslySetInnerHTML={{__html: t('headline') }} />
      </div>
      <div className="copyright hidden-desktop">
        {copyright}
      </div>
      <MenuFooter />
    </div>
    <div className="copyright hidden-mobile">
      {copyright}
    </div>
  </footer>
}